<template>
    <ConfigProvider :theme-vars="themeVars">
        <div class="m-app-container">
            <router-view v-if="globalStore.inited"></router-view>
        </div>
    </ConfigProvider>
</template>

<style lang="less" scoped>
.m-app-container {
    width: 100%;
}
</style>

<script setup>
import { ref } from 'vue';
import { useUserStore, useGlobalStore } from '@/stores/global';
import { useRoute } from 'vue-router';

import { ConfigProvider } from 'vant';

import { getCurrentUser } from '@/common/API';

const userStore = useUserStore();
const globalStore = useGlobalStore();

const route = useRoute();

let themeVars = ref({
    buttonPrimaryBackground: '#0B6EE2',
    buttonPrimaryBorderColor: '#0B6EE2',
});

function create() {
    globalStore.updateGlobalConfig();
    if (route.name === 'login') {
        return;
    }
    getCurrentUser().then(res => {
        if (res.error_no !== 0) {
            return;
        }
        userStore.setCurrentUser({
            username: res.data.userName,
            roles: res.data.role_list,
        });
    });
    // 移动端不适合在body设置overflow：hidden；所以需要在移动端app入口中覆盖body样式
    document.body.style.overflowY = 'auto';
}

create();
</script>
