/**
 * @file tools
 * @description 一些通用的简单的，又有点繁琐的工具集
 */

import dayjs from 'dayjs';
dayjs.extend(require('dayjs/plugin/weekOfYear'));

export function isNullOrUndefined(obj) {
    return obj === undefined || obj === null;
}

export function isEmptyArray(array) {
    // 在此函数场景下，如果传入的array类型不对，也返回true，表示当前array不是可用的array
    return !Array.isArray(array) || array.length < 1;
}

export function isEmptyObject(obj) {
    return isNullOrUndefined(obj) || Object.keys(obj).length < 1;
}

const tenThousand = 10000;

export function formatNumber(value, fixCount = 2) {
    if (isNullOrUndefined(value)) {
        return '-';
    }
    let absValue = Math.abs(value);
    if (absValue < tenThousand) {
        return value.toFixed(fixCount);
    }
    if (absValue < tenThousand * tenThousand) {
        return (value / tenThousand).toFixed(fixCount) + '万';
    }
    if (absValue < tenThousand * tenThousand * tenThousand) {
        return (value / (tenThousand * tenThousand)).toFixed(fixCount) + '亿';
    }
    return (
        (value / (tenThousand * tenThousand * tenThousand)).toFixed(fixCount) +
        '万亿'
    );
}

/**
 * 格式化数字以三位分节法样式返回字符串
 * @param {*} number
 * @returns
 */
export function formatNumberWithCommas(number) {
    let parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}

export function formatAxisData(axisData, granularity = 'day') {
    if (granularity === 'day') {
        return axisData;
    }
    return axisData.map(item => {
        let date = dayjs(item);
        // 周 和 相对周
        if (granularity === 'week' || granularity === '7') {
            return `第${date.week()}周`;
        }
        return date.format('YYYY-MM');
    });
}

export function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );
}

export function redirectToMaxConnect(state = '') {
    let host =
        process.env.VUE_APP_CONNECT_HOST || 'https://connect.spotmaxtech.com';
    let appkey = '000c4acc78e385bb76b07f4ca599c5d0';
    location.href = `${host}/api/v1/connect/authorize?appkey=${appkey}&redirect_uri=${encodeURIComponent(
        `${location.origin}/user/connect_login`
    )}&state=${encodeURIComponent(state)}`;
}
