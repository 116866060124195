/**
 * @file ParallelPromisePool
 * @description 实现一个任务队列管理器，支持有上限的并行执行任务
 */

export default class ParallelPromisePool {
    tasks = [];

    limitCount = 0;

    running = 0;

    runnedTasks = [];

    constructor(limit = 999) {
        this.limitCount = limit;
    }

    create(promiseGetter) {
        if (typeof promiseGetter !== 'function') {
            throw new Error('PromiseGetter must be a function');
        }
        setTimeout(() => this.excute(), 0);
        return new Promise((resolve, reject) => {
            this.tasks.push({
                exec: promiseGetter,
                resolve,
                reject,
            });
        });
    }

    excute() {
        if (this.tasks.length < 1 || this.running >= this.limitCount) {
            this.runnedTasks = this.tasks.length < 1 ? [] : this.runnedTasks;
            return;
        }
        let task = this.tasks.shift();
        this.running++;
        task.exec()
            .then(res => {
                task.resolve(res);
            })
            .catch(err => {
                task.reject(err);
            })
            .finally(() => {
                this.running--;
                this.excute();
            });
        this.runnedTasks.push(task);
    }

    clear() {
        this.tasks.forEach(task => {
            task.reject(Error('Promise Pool Clear Task'));
        });
        this.tasks = [];
        this.runnedTasks.forEach(task => {
            task.reject(Error('Promise Pool Clear Task'));
        });
        this.runnedTasks = [];
        this.running = 0;
    }
}
