<template>
    <n-config-provider
        :locale="zhCN"
        :date-locale="dateZhCN"
        :theme-overrides="themeOverrides"
    >
        <n-dialog-provider>
            <n-message-provider>
                <div class="app-container">
                    <Header v-if="!isIframe"></Header>
                    <router-view v-if="inited"></router-view>
                </div>
            </n-message-provider>
        </n-dialog-provider>
    </n-config-provider>
</template>

<script>
import { mapActions, mapState } from 'pinia';

import {
    NConfigProvider,
    NMessageProvider,
    NDialogProvider,
    zhCN,
    dateZhCN,
} from 'naive-ui';
import Header from './components/Header.vue';

import { useUserStore, useGlobalStore } from '@/stores/global';

import { getCurrentUser } from '@/common/API';

export default {
    name: 'App',
    components: {
        NMessageProvider,
        NConfigProvider,
        NDialogProvider,
        Header,
    },
    data() {
        return {
            zhCN,
            dateZhCN,
            themeOverrides: {
                common: {
                    primaryColor: '#0B6EE2',
                    primaryColorHover: '#0964CF',
                    primaryColorSuppl: '#0964CF',
                },
            },
        };
    },
    computed: {
        // 平台整体需依赖config数据请求完成，但是也不依赖是否请求成功，如果因服务原因导致请求失败，前端也可酌情应用本地配置
        ...mapState(useGlobalStore, ['inited']),
        isIframe() {
            return this.$route.query.iframe === '1';
        },
    },
    created() {
        this.updateGlobalConfig();
        if (this.$route.name !== 'login') {
            getCurrentUser().then(res => {
                if (res.error_no !== 0) {
                    return;
                }
                this.setCurrentUser({
                    username: res.data.userName,
                    roles: res.data.role_list,
                });
            });
        }
    },
    methods: {
        ...mapActions(useUserStore, ['setCurrentUser']),
        ...mapActions(useGlobalStore, ['updateGlobalConfig']),
    },
};
</script>

<style lang="less">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: @bg-color;
    .app-container {
        width: 100vw;
        height: 100vh;
        display: flex;
        flex-direction: column;
    }
}
</style>
