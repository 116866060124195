import { createWebHistory, createRouter } from 'vue-router';

import Log from '../common/log';

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/Home/Index'),
    },
    {
        path: '/user',
        children: [
            {
                name: 'login',
                path: 'login',
                component: () => import('@/views/User/Login'),
            },
            {
                path: 'connect_login',
                component: () => import('@/views/User/ConnectLogin'),
            },
        ],
    },
    {
        path: '/financial',
        name: 'financial',
        component: () => import('@/views/Container'),
        redirect: { name: 'management_view' },
        children: [
            {
                name: 'management_view',
                path: 'management_view',
                component: () =>
                    import('@/views/Financial/ManagementView/Index'),
            },
            {
                name: 'accrual_view',
                path: 'accrual_view',
                component: () => import('@/views/Financial/AccrualView/Index'),
            },
            {
                name: 'cloud_cost_stat_view',
                path: 'cloud_cost_stat',
                component: () => import('@/views/Financial/Case/CloudCostStat'),
            },
            {
                name: 'multi_dimension_cost_view',
                path: 'multi_dimension_cost',
                component: () =>
                    import('@/views/Financial/Case/MultiDimensionCost'),
            },
            {
                name: 'spotmax_cost_view',
                path: 'spotmax_cost',
                component: () => import('@/views/Financial/Case/SpotMaxCost'),
            },
        ],
    },
    {
        path: '/business',
        name: 'business',
        component: () => import('@/views/Container'),
        children: [
            {
                name: 'mtg_view',
                path: 'mtg/view',
                component: () => import('@/views/Business/Index'),
            },
            {
                name: 'mtg_dashboard',
                path: 'dashboard/mtg',
                component: () => import('@/views/Business/MTGDashboard/Index'),
            },
            // {
            //     name: 'project_manage_view',
            //     path: 'project/list',
            //     component: () => import('@/views/Business/ProjectManage/Index'),
            // },
        ],
    },
    {
        path: '/cost_tree',
        name: 'cost_tree',
        component: () => import('@/views/Container'),
        redirect: { path: '/cost_tree/cost/_default/_default' },
        children: [
            {
                name: 'cost_view',
                path: 'cost/:model/:name',
                component: () => import('@/views/CostTree/Cost/Index'),
            },
            {
                name: 'tag_manage_view',
                path: 'tag/:model/list',
                component: () => import('@/views/CostTree/TagManage/Index'),
            },
            {
                name: 'node_manage_view',
                path: 'node/:model/list',
                component: () => import('@/views/CostTree/NodeManage/Index'),
            },
        ],
    },
    {
        path: '/cost_lens',
        name: 'cost_lens',
        component: () => import('@/views/Container'),
        children: [
            {
                name: 'collection_view',
                path: 'collection/_list',
                component: () => import('@/views/CostLens/Index'),
            },
        ],
    },
    {
        path: '/cost_nerv',
        name: 'cost_nerv',
        component: () => import('@/views/Container'),
        children: [
            {
                name: 'cost_nerv_model_list',
                path: 'model/_list',
                component: () => import('@/views/CostNerv/ModelList'),
            },
            {
                name: 'cost_nerv_model_view_{id}',
                path: 'model/:id',
                component: () => import('@/views/CostNerv/ModelDataView'),
            },
            {
                name: 'cost_nerv_model_editor',
                path: 'model/:id/editor',
                component: () => import('@/views/CostNerv/ModelEditor'),
            },
        ],
    },
    {
        path: '/alarms',
        name: 'alarms',
        component: () => import('@/views/Container'),
        children: [
            {
                name: 'alarm_list',
                path: '_list',
                component: () => import('@/views/Alarms/Index'),
            },
        ],
    },
    {
        path: '/events',
        name: 'events',
        component: () => import('@/views/Container'),
        children: [
            {
                name: 'event_list',
                path: '_list',
                component: () => import('@/views/Events/Index'),
            },
        ],
    },
    {
        path: '/cases',
        name: 'cases',
        component: () => import('@/views/Container'),
        children: [
            {
                name: 'kw_collection',
                path: 'kw',
                component: () => import('@/views/Business/Analysis/Index'),
            },
            {
                name: 'online_diagram',
                path: 'onlinediagram',
                component: () =>
                    import('@/views/Business/Analysis/online/Diagram'),
            },
            {
                name: 'tracking_diagram',
                path: 'trackingdiagram',
                component: () =>
                    import('@/views/Business/Analysis/tracking/Diagram'),
            },
            {
                name: 'datamodel_diagram',
                path: 'datamodeldiagram',
                component: () =>
                    import('@/views/Business/Analysis/datamodel/Diagram'),
            },
        ],
    },
    {
        path: '/settings',
        name: 'settings',
        component: () => import('@/views/Container'),
        redirect: { name: 'setting_index' },
        children: [
            {
                name: 'setting_index',
                path: 'index',
                component: () => import('@/views/Settings/Index/Index'),
            },
            {
                name: 'users_setting',
                path: 'users',
                component: () => import('@/views/Settings/Auth/Index'),
            },
            {
                name: 'biz_mapping',
                path: 'biz_mapping',
                component: () => import('@/views/Settings/BizMapping/Index'),
            },
            {
                name: 'global_config',
                path: 'global',
                component: () => import('@/views/Settings/GlobalConfig/Index'),
            },
        ],
    },
    {
        path: '/labs',
        name: 'labs',
        component: () => import('@/views/Container'),
        redirect: { name: 'gpt_chart' },
        children: [
            {
                name: 'gpt_chart',
                path: 'gpt_chart',
                component: () => import('@/views/FinOpsLab/GPTChart/Index'),
            },
            {
                name: 'editor_test',
                path: 'editor_test',
                component: () => import('@/views/FinOpsLab/CodeMirror/Index'),
            },
        ],
    },
    {
        // 未命中任何路由规则的路由，全部跳转到首页
        path: '/:any*',
        redirect: { path: '/' },
    },
];

export const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.afterEach(to => {
    let toName = to.name;
    for (let key in to.params) {
        toName = toName.replaceAll(`{${key}}`, to.params[key]);
    }
    Log.pv(toName);
});
