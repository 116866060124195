/**
 * @file Log
 */

const URL = `http://${location.host}/api/v1/raw/user_behavior/log.gif`;

export default class Log {
    static pv(name) {
        let img = new Image(1, 1);
        img.src = `${URL}?action=pv&name=${name}`;
    }

    static click(name) {
        let img = new Image(1, 1);
        img.src = `${URL}?action=click&name=${name}`;
    }
}
