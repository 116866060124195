import { createWebHistory, createRouter } from 'vue-router';

import Log from '../common/log';

const routes = [
    {
        path: '/',
        name: 'index',
        component: () => import('@/views/Mobile/Home/Index'),
    },
    {
        path: '/cost_tree',
        name: 'cost_tree',
        children: [
            {
                name: 'cost_view',
                path: 'cost/:model/:name',
                component: () => import('@/views/Mobile/CostTree/Cost/Index'),
            },
        ],
    },
    {
        path: '/financial',
        name: 'financial',
        children: [
            {
                name: 'cloud_cost_stat_view',
                path: 'cloud_cost_stat',
                component: () =>
                    import('@/views/Mobile/Financial/Case/CloudCostStat'),
            },
        ],
    },
    {
        path: '/business',
        name: 'business',
        children: [
            {
                name: 'cost_view_redirect',
                path: 'cost/:model/:name',
                redirect: to => {
                    return {
                        path: `/cost_tree/cost/${to.params.model}/${to.params.name}`,
                    };
                },
            },
            {
                name: 'mtg_dashboard',
                path: 'dashboard/mtg',
                component: () =>
                    import('@/views/Mobile/Business/MTGDashboard/Index'),
            },
        ],
    },
    {
        path: '/user',
        children: [
            {
                name: 'login',
                path: 'login',
                component: () => import('@/views/Mobile/Login/Index'),
            },
        ],
    },
    {
        // 未命中任何路由规则的路由，全部跳转到首页
        path: '/:any*',
        redirect: { path: '/' },
    },
];

export const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.afterEach(to => {
    Log.pv('mobile_' + to.name);
});
