/**
 * @file Web App Global Store
 */

import { defineStore } from 'pinia';

import { getGlobalConfig } from '@/common/API';

export const useUserStore = defineStore('loginUser', {
    state: () => ({ username: 'guest01', roles: [], admin: 0 }),
    getters: {
        currentUser(state) {
            return {
                username: state.username,
                roles: state.roles,
                admin: state.admin,
            };
        },
    },
    actions: {
        setCurrentUser(userInfo) {
            this.username = userInfo.username;
            this.roles = userInfo.roles;
            this.admin = this.roles.indexOf('administrator') > -1;
        },
    },
});

export const useGlobalStore = defineStore('globalState', {
    state: () => ({
        initOk: false,
        todayOffset: -2,
        npsVersion: '20230130',
        // 旧版默认模块map，后续会下线该配置
        defaultBUNodeMap: {},
        defaultBUNodeMapv2: {},
        defaultTree: 'default',
        menuSettings: null,
        specialUsers: [],
    }),
    getters: {
        globalConfig(state) {
            return {
                todayOffset: state.todayOffset,
                npsVersion: state.npsVersion,
                defaultBUNodeMap: state.defaultBUNodeMap,
                defaultTree: state.defaultTree,
                defaultBUNodeMapv2: state.defaultBUNodeMapv2,
                menuSettings: state.menuSettings,
                specialUsers: state.specialUsers,
            };
        },
        inited(state) {
            return state.initOk;
        },
    },
    actions: {
        updateGlobalConfig() {
            getGlobalConfig()
                .then(res => {
                    this.initOk = true;
                    if (res.error_no !== 0) {
                        console.warn('global config load error');
                        return;
                    }
                    this.todayOffset = res.data.todayOffset || this.todayOffset;
                    this.npsVersion = res.data.npsVersion || this.npsVersion;
                    this.defaultBUNodeMap = res.data.defaultBUNodeMap || {};
                    this.defaultBUNodeMapv2 = res.data.defaultBUNodeMapv2 || {};
                    this.defaultTree = res.data.defaultTree || 'default';
                    this.menuSettings = res.data.menuSettings;
                    this.specialUsers = res.data.specialUsers || [];
                })
                .catch(err => {
                    this.initOk = true;
                    console.log(err);
                    console.warn('global config load error');
                });
        },
    },
});
