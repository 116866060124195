<template>
    <div :class="{ 'app-header': true, 'no-border': noBorder }">
        <router-link to="/">
            <img class="logo" src="../assets/logo.png" />
        </router-link>
        <div v-if="isLogin" class="user-name-view">
            <n-icon class="account-icon">
                <account-circle-outlined></account-circle-outlined>
            </n-icon>
            <n-popover trigger="hover">
                <template #trigger>
                    <span class="username">
                        {{ store.currentUser.username }}
                    </span>
                </template>
                <div class="user-info-tooltip">
                    <div class="group-info">用户组：{{ baseRoleName }}</div>
                    <n-button
                        v-if="store.currentUser.admin"
                        style="margin-bottom: 4px"
                        size="tiny"
                        @click="onFinOpsAdminClick"
                        block
                        >平台管理</n-button
                    >
                    <n-button
                        type="primary"
                        size="tiny"
                        @click="onLogoutClick"
                        block
                        >注销</n-button
                    >
                </div>
            </n-popover>
            <n-icon class="arrow-down-icon">
                <arrow-drop-down-sharp></arrow-drop-down-sharp>
            </n-icon>
        </div>
    </div>
</template>

<style lang="less" scoped>
.app-header {
    background-color: @fill-color;
    display: flex;
    padding: 0 32px;
    height: 55px;
    align-items: center;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    flex-shrink: 0;

    a {
        text-decoration: none;
    }

    .logo {
        width: 130px;
        margin-top: 10px;
    }

    .user-name-view {
        margin-left: auto;
        color: @text-8-color;
        font-size: 16px;
        font-weight: 500;
        display: flex;
        flex-direction: row;
        align-items: center;

        .account-icon {
            margin-right: 15px;
            font-size: 24px;
        }
        .arrow-down-icon {
            margin-left: 4px;
            font-size: 20px;
        }
        .username {
            cursor: pointer;
        }
    }
}
.no-border {
    border-bottom: 1px solid transparent;
}
.user-info-tooltip {
    .group-info {
        margin-bottom: 8px;
    }
}

.score-tips {
    display: flex;
    flex-direction: row;
    list-style: decimal;
    justify-content: space-between;
    padding: 0;
    margin-top: 0;
}
</style>

<script setup>
import { computed } from 'vue';

import { useRouter, useRoute } from 'vue-router';

import { NIcon, NPopover, NButton, useMessage } from 'naive-ui';

import { ArrowDropDownSharp, AccountCircleOutlined } from '@vicons/material';

import { useUserStore, useGlobalStore } from '@/stores/global';

import { userLogout } from '@/common/API';

const store = useUserStore();

const globalStore = useGlobalStore();

const router = useRouter();

const route = useRoute();

const message = useMessage();

let isLogin = computed(
    () => store.currentUser.username && store.currentUser.username !== 'guest01'
);

const baseRoleName = computed(() => {
    let rolesMap = {
        administrator: '平台管理员',
        financial_staff: '财务组',
        operator_staff: '运维组',
    };
    let roles = store.currentUser.roles;
    for (let key in rolesMap) {
        if (roles.includes(key)) {
            return rolesMap[key];
        }
    }
    return 'Mobvista成员';
});

const noBorder = computed(() => ['/', '/user/login'].indexOf(route.path) > -1);

function onLogoutClick() {
    userLogout().then(res => {
        location.href = '/';
    });
}

function onFinOpsAdminClick() {
    router.push('/settings');
}
</script>
