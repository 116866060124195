import axios from 'axios/index';

import qs from 'qs';

import ParallelPromisePool from '@/common/ParallelPromisePool';

import { redirectToMaxConnect } from '@/common/tools';

const PREFIX = '/api/v1';

function base() {
    const instance = axios.create({
        timeout: 1000 * 60 * 3,
        withCredentials: true,
        paramsSerializer(params) {
            return qs.stringify(params, { indices: false });
        },
    });
    // 识别URISearch中是否包含token
    let match = /(?:\?|&)token=([^&]+)(?:&|$)/.exec(location.search);

    let token =
        match !== null
            ? `Bearer ${match[1]}`
            : sessionStorage.getItem('finops_token');
    if (token) {
        instance.defaults.headers.common.Authorization = token;
        sessionStorage.setItem('finops_token', token);
    }
    instance.interceptors.response.use(
        res => {
            if (
                res.data.error_no === 10401 &&
                location.pathname !== '/user/login' &&
                location.pathname !== '/user/connect_login' &&
                location.pathname !== '/'
            ) {
                let refer = location.href.replace(location.origin, '');
                redirectToMaxConnect(refer);
                return new Promise(() => {});
            }
            return res.data;
        },
        err => {
            return Promise.reject(err);
        }
    );
    return instance;
}

function formData(data) {
    let kvStrArray = [];
    for (let key in data) {
        kvStrArray.push(`${key}=${encodeURIComponent(data[key])}`);
    }
    return kvStrArray.join('&');
}

const FINANCIAL_PREFIX = PREFIX + '/financial';

export class FinancialAPI {
    static getDateVersions() {
        return base().get(
            `${FINANCIAL_PREFIX}/management_view/date_and_version_list`
        );
    }

    static getReports(year, month, version) {
        return base().get(`${FINANCIAL_PREFIX}/management_view/report`, {
            params: { year, month, version: encodeURIComponent(version) },
        });
    }

    static diffReports(srcYear, srcMonth, srcVer, dstYear, dstMonth, dstVer) {
        return base().get(`${FINANCIAL_PREFIX}/management_view/report_diff`, {
            params: {
                src_year: srcYear,
                src_month: srcMonth,
                src_version: encodeURIComponent(srcVer),
                dst_year: dstYear,
                dst_month: dstMonth,
                dst_version: encodeURIComponent(dstVer),
            },
        });
    }

    static saveSnapShot(year, month, version) {
        return base().post(
            `${FINANCIAL_PREFIX}/management_view/save_current_snapshot`,
            null,
            {
                params: { year, month, version: encodeURIComponent(version) },
            }
        );
    }

    // static getCloudCostPeriodList() {
    //     return base().get(
    //         `${FINANCIAL_PREFIX}/hardcode/get_cloud_cost_finishing_rate_period_list`
    //     );
    // }

    // static getCloudCostStat(cloud = 'aws', period = 'Y1') {
    //     return base().get(
    //         `${FINANCIAL_PREFIX}/hardcode/cloud_cost_finishing_rate`,
    //         { params: { cloud, period } }
    //     );
    // }

    static getDailyCloudCost(start_date, end_date) {
        return base().get(
            `${FINANCIAL_PREFIX}/management_view/daily_cloud_cost`,
            { params: { start_date, end_date } }
        );
    }

    static getMonthlyCloudCost(year, month) {
        return base().get(
            `${FINANCIAL_PREFIX}/management_view/monthly_cloud_cost`,
            { params: { year, month } }
        );
    }

    static getDailyFinalCost(start_date, end_date) {
        return base().get(
            `${FINANCIAL_PREFIX}/management_view/daily_final_cost_by_monthly_diff`,
            { params: { start_date, end_date } }
        );
    }

    static getMonthlyFinalCost(year, month) {
        return base().get(
            `${FINANCIAL_PREFIX}/management_view/monthly_final_cost`,
            { params: { year, month } }
        );
    }
}

const ACCRUAL_VIEW_PREFIX = PREFIX + '/financial/accrual_view';

export class AccrualViewAPI {
    static getEnableDates() {
        return base().get(`${ACCRUAL_VIEW_PREFIX}/date_list`);
    }

    static getMVCompanyList() {
        return base().get(`${ACCRUAL_VIEW_PREFIX}/mv_company_entity_list`);
    }

    static getSupplierCompanyList(key) {
        return base().get(
            `${ACCRUAL_VIEW_PREFIX}/supplier_company_entity_list`,
            { params: { key } }
        );
    }

    static getReport(year, month) {
        return base().get(`${ACCRUAL_VIEW_PREFIX}/accrual_report`, {
            params: { year, month },
        });
    }

    static createReport(year, month, payload) {
        return base().post(`${ACCRUAL_VIEW_PREFIX}/accrual_report`, payload, {
            params: { year, month },
        });
    }

    static updateReport(id, payload) {
        return base().put(`${ACCRUAL_VIEW_PREFIX}/accrual_report`, payload, {
            params: { id },
        });
    }

    static deleteReport(id) {
        return base().delete(`${ACCRUAL_VIEW_PREFIX}/accrual_report`, {
            params: { id },
        });
    }

    static getProjectTree() {
        return base().get(`${ACCRUAL_VIEW_PREFIX}/project_tree`);
    }

    static reviewAndCheck(year, month) {
        return base().get(
            `${ACCRUAL_VIEW_PREFIX}/accrual_report/review_and_check`,
            {
                params: { year, month },
            }
        );
    }

    static submitReport(year, month) {
        return base().post(
            `${ACCRUAL_VIEW_PREFIX}/accrual_report/submit`,
            null,
            { params: { year, month } }
        );
    }

    static submitSingleAccrual(year, month, id) {
        return base().post(
            `${ACCRUAL_VIEW_PREFIX}/accrual_report/submit`,
            null,
            { params: { year, month, id } }
        );
    }

    static withdrawReport(year, month) {
        return base().post(
            `${ACCRUAL_VIEW_PREFIX}/accrual_report/withdraw`,
            null,
            { params: { year, month } }
        );
    }

    static withdrawSingleAccrual(year, month, id) {
        return base().post(
            `${ACCRUAL_VIEW_PREFIX}/accrual_report/withdraw`,
            null,
            { params: { year, month, id } }
        );
    }

    static getAttachmentFiles(id) {
        return base().get(`${ACCRUAL_VIEW_PREFIX}/support_file/list/${id}`);
    }

    static getAttachmentUploadUrl(id) {
        return `${ACCRUAL_VIEW_PREFIX}/support_file/${id}`;
    }

    static getAttachmentUrl(id, fileName) {
        return `${ACCRUAL_VIEW_PREFIX}/support_file/${id}/${encodeURIComponent(
            fileName
        )}`;
    }

    static deleteAttachment(id, files) {
        return base().delete(`${ACCRUAL_VIEW_PREFIX}/support_file/${id}`, {
            params: {
                ...(files ? { filelist: files } : {}),
            },
        });
    }
}

// 即将废弃
export function getBusinessCosts(year, month) {
    return base().get(`${PREFIX}/business/mtg_view/report`, {
        params: {
            year,
            month,
        },
    });
}

// 即将废弃
export function getBusinessAvaliableDate() {
    return base().get(`${PREFIX}/business/mtg_view/available_date`);
}

const MV_BIZ_PREFIX = `${PREFIX}/business/mv_module`;

const MVBizViewPool = new ParallelPromisePool(2);

export class MVBizViewAPI {
    static getTreeList() {
        return base().get(`${MV_BIZ_PREFIX}/tree_list`);
    }

    static getStructTree(tree) {
        return base().get(`${MV_BIZ_PREFIX}/struct`, {
            params: { tree_type: tree },
        });
    }

    static getTags(tree, nodes) {
        return base().get(`${MV_BIZ_PREFIX}/tag`, {
            params: { tree_type: tree, node_list: nodes },
        });
    }

    static getOverview(query) {
        return MVBizViewPool.create(() =>
            base().post(`${MV_BIZ_PREFIX}/cost_overview`, query)
        );
    }

    static getCostContrast(query) {
        return MVBizViewPool.create(() =>
            base().post(`${MV_BIZ_PREFIX}/cost_contrast`, query)
        );
    }

    static getViewData(query) {
        return MVBizViewPool.create(() =>
            base().post(`${MV_BIZ_PREFIX}/data_view`, query)
        );
    }

    static getAnalysisData(query) {
        return MVBizViewPool.create(() =>
            base().post(`${MV_BIZ_PREFIX}/data_statistics`, query)
        );
    }

    static abortViewAndAnalysisRequest() {
        MVBizViewPool.clear();
    }

    static getTagMList(tree) {
        return base().get(`${MV_BIZ_PREFIX}/tag_list`, {
            params: { tree_type: tree },
        });
    }

    static checkTagExist(key, value) {
        return base().post(`${MV_BIZ_PREFIX}/check_tag_repeat`, { key, value });
    }

    static getTreeNodes(tree, keyword) {
        return base().get(`${MV_BIZ_PREFIX}/get_tree_node`, {
            params: { tree_type: tree, keyword },
        });
    }

    static createTag(payload) {
        return base().post(`${MV_BIZ_PREFIX}/upsert_tag`, payload);
    }

    static updateTag(payload) {
        return base().put(`${MV_BIZ_PREFIX}/upsert_tag`, payload);
    }

    static deleteTag(tree, key, value) {
        return base().post(`${MV_BIZ_PREFIX}/delete_tag`, {
            list: [{ key, value }],
            tree_type: tree,
        });
    }

    static updateNode(payload) {
        return base().post(`${MV_BIZ_PREFIX}/update_node`, payload);
    }
}

// 即将废弃
export function userLogin(username, password) {
    return base().post(
        `${PREFIX}/user/login`,
        formData({
            username: encodeURIComponent(username),
            password: encodeURIComponent(password),
        })
    );
}

export function userConnectLogin(code) {
    return base().post(`${PREFIX}/user/connect_login`, { code });
}

export function userLogout() {
    return base().get(`${PREFIX}/user/logout`);
}

export function getCurrentUser() {
    return base().get(`${PREFIX}/user/current`);
}

const SETTINGS_PREFIX = `${PREFIX}/setting`;

export class SettingsAPI {
    static getRoles() {
        return base().get(`${SETTINGS_PREFIX}/auth/role`);
    }

    static getUsersByRole(role) {
        return base().get(`${SETTINGS_PREFIX}/auth/user`, { params: { role } });
    }

    static addUserToRole(user, role) {
        return base().post(`${SETTINGS_PREFIX}/auth/user`, null, {
            params: { user, role },
        });
    }

    static removeUserToRole(user, role) {
        return base().delete(`${SETTINGS_PREFIX}/auth/user`, {
            params: { user, role },
        });
    }

    static getMappingRaw() {
        return base().get(`${SETTINGS_PREFIX}/tree/raw`);
    }

    static updateGlobalConfig(config) {
        return base().put(`${SETTINGS_PREFIX}/config/global`, config);
    }

    static clearRedisDB1Cache(index) {
        return base().get(`${SETTINGS_PREFIX}/config/clear_cache`, {
            params: { db: index },
        });
    }

    static preheatCache() {
        return base().post(`${SETTINGS_PREFIX}/config/preheat_cache`);
    }

    static createToken(identity, expire_time) {
        return base().post(`${SETTINGS_PREFIX}/token/create`, {
            identity,
            expire_time,
        });
    }

    static blockToken(jti, expire_time) {
        return base().post(`${SETTINGS_PREFIX}/token/block`, {
            jti,
            expire_time,
        });
    }
}

export function getGlobalConfig() {
    return base().get(`${PREFIX}/config/global`);
}

// 即将废弃
export function postNPSScore(score) {
    return base().get(`${PREFIX}/raw/nps_scoring`, {
        params: {
            score,
        },
    });
}

// 即将废弃
export function postGPTtoSQL(input = '') {
    return base().post('/api/v1/experiment/gpt_to_sql', { input });
}

const PROJECT_MANAGE_PREFIX = `${PREFIX}/business/project`;

export class ProjectManageAPI {
    static getSuggestion(field, filter) {
        return base().get(`${PROJECT_MANAGE_PREFIX}/suggestion`, {
            params: { field, ...filter },
        });
    }

    static getProjects() {
        return base().get(`${PROJECT_MANAGE_PREFIX}/list`);
    }

    static createProject(payload) {
        return base().post(`${PROJECT_MANAGE_PREFIX}`, payload);
    }

    static updateProject(id, payload) {
        return base().put(`${PROJECT_MANAGE_PREFIX}/${id}`, payload);
    }

    static deleteProject(id) {
        return base().delete(`${PROJECT_MANAGE_PREFIX}/${id}`);
    }

    static getResourcesByProjectId(id) {
        return base().get(`${PROJECT_MANAGE_PREFIX}/${id}/resource`);
    }
}

// 即将废弃
export function getMetaBaseDashboardUrl(id) {
    return base().get(`${PREFIX}/business/metabase_dashboard/${id}`);
}

export function getDashboard(start_time, end_time, granularity = 'day') {
    return base().get(`${PREFIX}/business/dashboard/mtg`, {
        params: {
            start_time,
            end_time,
            granularity,
        },
    });
}

const COLLECTION_PREFIX = `${PREFIX}/business/collection`;

const CollectionCostPool = new ParallelPromisePool(2);

const CollectionCostDataPool = new ParallelPromisePool(8);

export class CollectionAPI {
    static getCollectionList(index, size, category, name_prefix, tag, owner) {
        return base().get(`${COLLECTION_PREFIX}`, {
            params: {
                page: index,
                page_size: size,
                category,
                name_prefix,
                tag,
                owner,
            },
        });
    }

    static getCollection(id) {
        return base().get(`${COLLECTION_PREFIX}/${id}`);
    }

    static getCategories() {
        return base().get(`${COLLECTION_PREFIX}/categories`);
    }

    static createCollection(payload) {
        return base().post(`${COLLECTION_PREFIX}`, payload);
    }

    static updateCollection(id, payload) {
        return base().put(`${COLLECTION_PREFIX}/${id}`, payload);
    }

    static deleteCollection(id) {
        return base().delete(`${COLLECTION_PREFIX}/${id}`);
    }

    static getCostDataByCollectionId(
        id,
        start,
        end,
        granularity = 'day',
        dimension = 'all'
    ) {
        return CollectionCostPool.create(() =>
            base().get(`${COLLECTION_PREFIX}/${id}/data`, {
                params: {
                    start_time: start,
                    end_time: end,
                    granularity,
                    dimension,
                },
            })
        );
    }

    static getCostDataByCollectionIdM(
        id,
        start,
        end,
        granularity = 'day',
        dimension = 'all'
    ) {
        return CollectionCostDataPool.create(() =>
            base().get(`${COLLECTION_PREFIX}/${id}/data`, {
                params: {
                    start_time: start,
                    end_time: end,
                    granularity,
                    dimension,
                },
            })
        );
    }
}

export function getFilterSuggestion(type) {
    return base().get(`${PREFIX}/business/mv_module/filter_suggestion`, {
        params: { type },
    });
}

const CUSTOMIZATION_MADX_PREFIX = `${PREFIX}/customization/madx`;
const MADXPool = new ParallelPromisePool(2);

export class CUSTOMIZATION_MADX_API {
    static get_origin_metric(metric_name, params) {
        return base().get(
            `${CUSTOMIZATION_MADX_PREFIX}/metrics/${metric_name}`,
            {
                params: params,
            }
        );
    }

    static get_cost_share(view, metric_name, params) {
        return MADXPool.create(() =>
            base().get(
                `${CUSTOMIZATION_MADX_PREFIX}/cost/${view}/${metric_name}`,
                {
                    params: params,
                }
            )
        );
    }

    static get_metadata() {
        return base().get(`${CUSTOMIZATION_MADX_PREFIX}/metadata`);
    }
}
const ANALYSIS_PREFIX = `${PREFIX}/general/analysis`;

const CostNervPool = new ParallelPromisePool(2);

export class CostNervAPI {
    static getModelList(index, size = 10, creator) {
        return base().get(`${ANALYSIS_PREFIX}/model/list`, {
            params: {
                with_info: true,
                page: index,
                page_size: size,
                creator,
            },
        });
    }

    static getModel(id) {
        return base().get(`${ANALYSIS_PREFIX}/model/${id}`);
    }

    static createModel(payload) {
        return base().post(`${ANALYSIS_PREFIX}/model`, payload);
    }

    static updateModel(id, payload) {
        return base().put(`${ANALYSIS_PREFIX}/model/${id}`, payload);
    }

    static deleteModel(id) {
        return base().delete(`${ANALYSIS_PREFIX}/model/${id}`);
    }

    static getQueryList(modelId) {
        return base().get(`${ANALYSIS_PREFIX}/model/${modelId}/query/list`, {
            params: { with_info: true, page: 1, page_size: 99999 },
        });
    }

    static getQuery(modelId, queryId) {
        return base().get(
            `${ANALYSIS_PREFIX}/model/${modelId}/query/${queryId}`
        );
    }

    static createQuery(modelId, payload) {
        return base().post(
            `${ANALYSIS_PREFIX}/model/${modelId}/query`,
            payload
        );
    }

    static updateQuery(modelId, queryId, payload) {
        return base().put(
            `${ANALYSIS_PREFIX}/model/${modelId}/query/${queryId}`,
            payload
        );
    }

    static deleteQuery(modelId, queryId) {
        return base().delete(
            `${ANALYSIS_PREFIX}/model/${modelId}/query/${queryId}`
        );
    }

    static calculateQuery(
        modelId,
        queryId,
        start,
        end,
        granularity,
        filter = {}
    ) {
        return CostNervPool.create(() =>
            base().get(
                `${ANALYSIS_PREFIX}/model/${modelId}/query/${queryId}/calculation`,
                {
                    params: {
                        start_timestamp: start,
                        end_timestamp: end,
                        ...(granularity ? { granularity } : null),
                        filter: JSON.stringify(filter),
                    },
                }
            )
        );
    }

    static previewQuery(payload, start, end) {
        return base().post(
            `${ANALYSIS_PREFIX}/model/0/query/0/calculation`,
            payload,
            {
                params: {
                    start_timestamp: start,
                    end_timestamp: end,
                },
            }
        );
    }

    static getDataSets() {
        return base().get(`${ANALYSIS_PREFIX}/dataset/suggestion`);
    }

    static getPanelList(modelId) {
        return base().get(`${ANALYSIS_PREFIX}/model/${modelId}/panel/list`, {
            params: { with_info: true, page: 1, page_size: 99999 },
        });
    }

    static getPanel(modelId, panelId) {
        return base().get(
            `${ANALYSIS_PREFIX}/model/${modelId}/panel/${panelId}`
        );
    }

    static createPanel(modelId, payload) {
        return base().post(
            `${ANALYSIS_PREFIX}/model/${modelId}/panel`,
            payload
        );
    }

    static updatePanel(modelId, panelId, payload) {
        return base().put(
            `${ANALYSIS_PREFIX}/model/${modelId}/panel/${panelId}`,
            payload
        );
    }

    static deletePanel(modelId, panelId) {
        return base().delete(
            `${ANALYSIS_PREFIX}/model/${modelId}/panel/${panelId}`
        );
    }
}

const ALERT_PREFIX = `${PREFIX}/alert`;

export class AlertAPI {
    static getList(page, page_size, name, creator) {
        return base().get(`${ALERT_PREFIX}/cost_alert/_list`, {
            params: {
                page,
                page_size,
                name,
                creator,
            },
        });
    }

    static createAlert(payload) {
        return base().post(`${ALERT_PREFIX}/cost_alert`, payload);
    }

    static updateAlert(id, payload) {
        return base().put(`${ALERT_PREFIX}/cost_alert/${id}`, payload);
    }

    static deleteAlert(id) {
        return base().delete(`${ALERT_PREFIX}/cost_alert/${id}`);
    }

    static triggerAlert(id) {
        return base().get(`${ALERT_PREFIX}/cost_alert/${id}/_trigger`);
    }
}

const BA_PREFIX = `${PREFIX}/business_analysis`;

export class BusinessAnalysisAPI {
    static getDailyCloudCost(start_date, end_date) {
        return base().get(`${BA_PREFIX}/ba_view/daily_cloud_cost`, {
            params: {
                start_date,
                end_date,
            },
        });
    }

    static getDailyBUCost(start_date, end_date) {
        return base().get(`${BA_PREFIX}/ba_view/daily_bu_cost`, {
            params: {
                start_date,
                end_date,
            },
        });
    }

    static getSpotMaxDailyCost(start_date, end_date) {
        return base().get(`${BA_PREFIX}/ba_view/daily_spotmax_cost`, {
            params: {
                start_date,
                end_date,
            },
        });
    }

    static getCloudCostPeriodList() {
        return base().get(
            `${BA_PREFIX}/cloud_cost_rate/get_cloud_cost_finishing_rate_period_list`
        );
    }

    static getCloudCostStat(cloud = 'aws', period = 'Y1') {
        return base().get(
            `${BA_PREFIX}/cloud_cost_rate/cloud_cost_finishing_rate`,
            { params: { cloud, period } }
        );
    }
}

const EVENT_PREFIX = `${PREFIX}/event`;

export class EventAPI {
    static createEvent(payload) {
        return base().post(`${EVENT_PREFIX}`, payload);
    }

    static updateEvent(id, payload) {
        return base().put(`${EVENT_PREFIX}/${id}`, payload);
    }

    static setEventTarget(id, target) {
        return base().patch(`${EVENT_PREFIX}/${id}/target`, { target });
    }

    static getList(start_time, end_time, index, size, target, creator) {
        return base().get(`${EVENT_PREFIX}/_list`, {
            params: { start_time, end_time, index, size, target, creator },
        });
    }

    static deleteEvent(id) {
        return base().delete(`${EVENT_PREFIX}/${id}`);
    }
}
