import { createApp } from 'vue';
import { createPinia } from 'pinia';
import App from './App.vue';
import MobileApp from './MApp';
import { router } from './router/router';
import { router as mobileRouter } from './router/mrouter';
import { isMobile } from './common/tools';

const pinia = createPinia();

function initApp() {
    createApp(App).use(pinia).use(router).mount('#app');
}

function initMobileApp() {
    require('vant/lib/index.css');
    createApp(MobileApp).use(pinia).use(mobileRouter).mount('#app');
}

isMobile() ? initMobileApp() : initApp();
